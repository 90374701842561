import React from "react";
import {
    HashRouter,
    Route,
    Switch
} from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import {MuiThemeProvider} from "@material-ui/core/styles";
import {createMuiTheme} from "@material-ui/core/styles";
import {useSelector} from 'react-redux';
import Settings from "./containers/Setting/Settings";
import {getTheme} from "./containers/Setting/settingsReducer";
import {useKeycloak} from "@react-keycloak/web";
import {PrivateRoute} from "./utils/utils";
import LoginPage from "./containers/Login";
import Loader from "./components/Loader";

import {Box, Grid} from "@material-ui/core";
import Invoices from "./containers/Invoices/Invoices";
import Reports from "./containers/Reports/Reports";


const NotFound = () => {
    return <Grid container justify = "center">
        <Box p={10}>Nema informacija</Box>
    </Grid>;
};

export default function App() {
    const {initialized} = useKeycloak()
    const theTheme = useSelector(getTheme);

    if (!initialized) {
        return <Loader/>
    }

    return (
        <MuiThemeProvider theme={createMuiTheme(theTheme)}>
            <CssBaseline/>
            <div style={{height: "100vh"}}>
                <HashRouter>
                    <Switch>
                        <PrivateRoute exact path="/settings" component={Settings} name="Podešavanja"/>
                        <Route exact path="/login" component={LoginPage}/>
                        <PrivateRoute exact path="/" component={Invoices} name="Naslovna"/>
                        <PrivateRoute exact path="/reports" component={Reports} name="Promet po vrsti plaćanja"/>
                        <PrivateRoute component={NotFound}/>
                    </Switch>
                </HashRouter>
            </div>
        </MuiThemeProvider>
    );
};