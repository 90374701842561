import React, {Fragment, useEffect} from "react";
import {withStyles} from "@material-ui/core/styles";
import classNames from "classnames";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import {
    Avatar,
    Backdrop,
    CircularProgress,
    Dialog,
    DialogTitle,
    ListItem,
    ListItemAvatar, ListItemText,
    Snackbar
} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {
    getCurrentRole, getDrawerOpened,
    getIsLoading,
    getSnackbar,
    getUser,
    setCurrentRole,
    setIsLoading,
    setSnackbar,
    setDrawerOpened, setCompanies, setEnus
} from '../containers/mainReducer'
import MuiAlert from '@material-ui/lab/Alert';
import List from "@material-ui/core/List";
import {Person} from "@material-ui/icons";
import {useAxios} from "../utils/hooks";
import {translateRole} from "../components/Header/Header";
import WarningMessage from '../components/WarningMessage'

const drawerWidth = 240;

const styles = theme => ({
    root: {
        display: "flex"
    },
    content: {
        flexGrow: 1,
        marginLeft: theme.spacing(7),
        padding: theme.spacing(2),
        marginTop: theme.spacing(7),
        overflowX: "hidden"
    },
    contentShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const MainLayout = ({classes, children}) => {

    const drawerOpened = useSelector(getDrawerOpened);
    const currentRole = useSelector(getCurrentRole);
    const user = useSelector(getUser);
    const snackbar = useSelector(getSnackbar);
    const isLoading = useSelector(getIsLoading);
    const dispatch = useDispatch();

    const handleToggleDrawer = () => {
      dispatch(setDrawerOpened(!drawerOpened));
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(setSnackbar({
            show: false,
            message: "",
            type: null
        }));

    };

    const handleChangeCurrentRole = (role) => {
        dispatch(setCurrentRole(role));
    }

    const axiosInstance = useAxios();

    useEffect(() => {
        const getCompanies = () => {
            dispatch(setIsLoading(true));
            if (!!axiosInstance.current) {
                axiosInstance.current.get(`/users/${user.sub}`)
                    .then(response => {
                        if (response && response.data) {
                            dispatch(setCompanies(response.data));
                        }
                    })
                    .finally(() => {
                        dispatch(setIsLoading(false));
                    });
            }
        };

        switch (currentRole) {
            case "OWNER":
                getCompanies();
                break;
            default:

        }
    }, [dispatch, axiosInstance, currentRole, user]);

    return (
        <Fragment>
            <div className={classes.root}>
                <Header
                    handleToggleDrawer={handleToggleDrawer}
                />
                <main
                    className={classNames(classes.content, {
                        [classes.contentShift]: drawerOpened
                    })}
                >
                    <WarningMessage />
                    {children}
                </main>
            </div>
            <Sidebar open={drawerOpened} drawerWidth={drawerWidth}/>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={snackbar.show}
                autoHideDuration={7000}
                onClose={handleCloseSnackBar}
            >
                {snackbar.type &&
                <Alert onClose={handleCloseSnackBar} severity={snackbar.type}>
                    {snackbar.message}
                </Alert>
                }
            </Snackbar>
            <Backdrop className={classes.backdrop} open={isLoading}>
                <CircularProgress color="inherit"/>
            </Backdrop>

            {user && user.roles.length > 0 && !currentRole &&
            <Dialog aria-labelledby="simple-dialog-title" open={!currentRole}>
                <DialogTitle id="simple-dialog-title">Odaberite ulogu</DialogTitle>
                <List>
                    {user.roles.map((role) => (
                        <ListItem button onClick={() => handleChangeCurrentRole(role)} key={role}>
                            <ListItemAvatar>
                                <Avatar className={classes.avatar}>
                                    <Person/>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={translateRole(role)}/>
                        </ListItem>
                    ))}

                </List>
            </Dialog>
            }
        </Fragment>
    );
}

export default withStyles(styles)(MainLayout);
