import React, {useEffect, useState} from 'react'
import Typography from '@material-ui/core/Typography'
import MUIDataTable from 'mui-datatables'
import {
    Box, Button, Card, CardContent,
    Paper,
     SwipeableDrawer,
    Table,
    TableBody,
    TableCell,
    TableContainer, TableFooter,
    TableHead,
    TableRow
} from '@material-ui/core'
import {useAxios} from '../../utils/hooks'
import {
    getCurrentCompany, getCurrentEnu,
    setIsLoading,
} from '../mainReducer'
import {useDispatch, useSelector} from 'react-redux'
import moment from 'moment-timezone'
import MomentUtils from '@date-io/moment'
import 'moment/locale/me'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers'
import Grid from '@material-ui/core/Grid'
import {Pagination} from '@material-ui/lab'
import {useInvoicesListStyles} from "../../utils/helpers";
import clsx from 'clsx'

const rowsPerPage = 10
const columns = [
    {
        name: 'id',
        label: 'Id',
        options: {
            display: false,
        },
    },
    {
        name: 'broj',
        label: 'RB',
    },
    {
        name: 'datum',
        label: 'Datum',
        options: {
            customBodyRender: value => moment(value).utc().format('DD/MM/YYYY HH:mm')
        },
    },
    {
        name: 'partner',
        label: 'Partner',
    },
    {
        name: 'kasa',
        label: 'Kasa',
    },
    {
        name: 'sto',
        label: 'Sto',
    },
    {
        name: 'konobar',
        label: 'Konobar',
    },
    {
        name: 'vrstaplacanja',
        label: 'Vrsta placanja',
    },
    {
        name: 'iznos',
        label: 'Ukupno',
        options: {
            setCellProps: () => ({style: {width: 70}}),
            customBodyRender: value => value ? value.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }) : 0
        },
    },
    {
        name: 'tipracuna',
        label: 'Tip racuna',
        options: {
            hide: true,
        },
    }
]

export default function Invoices() {
    const classes = useInvoicesListStyles()
    const [invoices, setInvoices] = useState([])
    const [invoicesCount, setInvoicesCount] = useState(0)
    const [invoicesSum, setInvoicesSum] = useState(0)
    const [from, setFrom] = useState(0)
    const [dateFrom, setDateFrom] = useState(moment().startOf('day').valueOf())
    const [dateTo, setDateTo] = useState(moment().endOf('day').valueOf())
    const dispatch = useDispatch()
    const currentCompany = useSelector(getCurrentCompany)
    const currentEnu = useSelector(getCurrentEnu)
    const [openInvoiceWithId, setOpenInvoiceWithId] = useState(null)
    const [openedInvoice, setOpenedInvoice] = useState(null)
    const [refreshCounter, setRefreshCounter] = useState(0)

    const axiosInstance = useAxios()

    useEffect(() => {
        if (openInvoiceWithId && currentCompany) {
            dispatch(setIsLoading(true))
            axiosInstance.current.get(`/invoices/${currentCompany.pib}/${openInvoiceWithId}`)
                .then(res => {
                    setOpenedInvoice(res.data)
                })
                .catch(err => {
                    console.log(err)
                })
                .finally(() => {
                    dispatch(setIsLoading(false))
                })
        } else {
            setOpenedInvoice(null)
        }
    }, [openInvoiceWithId, currentCompany, dispatch, axiosInstance])

    useEffect(() => {
        const dateFromUrl = encodeURIComponent(moment(dateFrom).format('YYYY-MM-DDTHH:mm:ss'))
        const dateToUrl = encodeURIComponent(moment(dateTo).format('YYYY-MM-DDTHH:mm:ss'))
        if (!!axiosInstance.current && currentCompany && currentEnu) {
            dispatch(setIsLoading(true))
            axiosInstance.current.get(`/invoices/${currentCompany.pib}?from=${from}&size=${rowsPerPage}&dateFrom=${dateFromUrl}&dateTo=${dateToUrl}&invoiceType=racun,order&enu=${currentEnu.naziv}`)
                .then(({data}) => {
                    setInvoices(data.invoices)
                    setInvoicesCount(data.total)
                    setInvoicesSum(data.sum)
                })
                .finally(() => {
                    dispatch(setIsLoading(false))
                })
        }
    }, [dispatch, axiosInstance, from, currentCompany, currentEnu, dateFrom, dateTo, refreshCounter])


    const options = {
        filter: false,
        search: false,
        download: false,
        sort: false,
        pagination: false,
        print: false,
        viewColumns: false,
        responsive: 'standard',
        serverSide: true,
        count: invoicesCount,
        rowsPerPage,
        rowsPerPageOptions: [],
        sortOrder: {},
        selectableRows: 'none',
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        textLabels: {
            body: {
                noMatch: 'Nema podataka',
                toolTip: 'Sortiraj',
                columnHeaderTooltip: column => `Sort for ${column.label}`
            },
            pagination: {
                next: 'Sledeća strana',
                previous: 'Prethodna strana',
                rowsPerPage: 'Redova po strani:',
                displayRows: 'od',
            }
        },
        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    setFrom(tableState.page)
                    break
                case 'search':
                    setFrom(tableState.page)
                    break
                default:
            }
        },
        setRowProps: (rowData) => {
            return {
                className: clsx({
                    [classes.OrderRow]: rowData[9].toLowerCase() === 'order'
                }),
            }
        },
        onRowClick: (rowData) => {
            setOpenInvoiceWithId(rowData[0])
        }
    }

    if (!currentCompany || !currentEnu) return null

    return (
        <>
            <Box>
                <Grid container style={{paddingTop: 80}}>
                    <MuiPickersUtilsProvider locale="me" utils={MomentUtils}>
                        <Grid item style={{width: '175px'}}>
                            <KeyboardDatePicker
                                autoOk
                                disableToolbar
                                size="small"
                                variant="inline"
                                inputVariant="outlined"
                                margin="normal"
                                id="date-picker-dialog-from"
                                label="Datum od"
                                format="DD.MM.yyyy."
                                value={moment(dateFrom)}
                                onChange={(value) => {
                                    setFrom(0)
                                    setDateFrom(moment(value).startOf('day').valueOf())
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                style={{paddingRight: 5}}
                            />
                        </Grid>
                        <Grid item style={{width: '175px'}}>
                            <KeyboardDatePicker
                                autoOk
                                disableToolbar
                                size="small"
                                variant="inline"
                                inputVariant="outlined"
                                margin="normal"
                                id="date-picker-dialog-to"
                                label="Datum do"
                                format="DD.MM.yyyy."
                                value={dateTo}
                                onChange={(value) => {
                                    setFrom(0)
                                    setDateTo(moment(value).endOf('day').valueOf())
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                style={{paddingLeft: 5, paddingRight: 5}}
                            />
                        </Grid>
                    </MuiPickersUtilsProvider>

                    <Button
                        color="default"
                        onClick={() => {
                            setRefreshCounter(refreshCounter + 1)
                        }}>
                        Osvježi
                    </Button>
                </Grid>
            </Box>

            <Box display="flex" p={1}>
                <Box p={1} flexGrow={0} style={{minWidth: 120}}>
                    <Card className={classes.root} variant="outlined">
                        <CardContent className={classes.smallerPadding}>
                            <Typography variant="h5" component="h2">
                                {invoicesSum ? invoicesSum.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                }) : 0}&euro;
                            </Typography>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                IZNOS
                            </Typography>
                        </CardContent>
                    </Card>
                </Box>
                <Box p={1} flexGrow={0} style={{minWidth: 120}}>
                    <Card className={classes.root} variant="outlined">
                        <CardContent className={classes.smallerPadding}>
                            <Typography variant="h5" component="h2">
                                {invoicesCount}
                            </Typography>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                RAČUNA
                            </Typography>
                        </CardContent>
                    </Card>
                </Box>
            </Box>

            <MUIDataTable
                title={
                    <Typography variant="h6">
                        Lista faktura
                    </Typography>
                }
                data={invoices}
                columns={columns}
                options={options}
            />
            <Box display="flex" justifyContent="center" p={1}>
                <Box p={2}>
                    <Pagination
                        color="primary"
                        showFirstButton
                        showLastButton
                        count={Math.ceil(invoicesCount / rowsPerPage)}
                        page={Math.floor(from + 1)}
                        siblingCount={0}
                        onChange={(e, v) => {
                            setFrom(v - 1)
                        }}
                    />
                </Box>
            </Box>

            {openedInvoice && <SwipeableDrawer
                anchor={'bottom'}
                open={!!openedInvoice}
                onClose={() => setOpenInvoiceWithId(null)}
                style={{maxHeight: '100px', overflowY: 'auto'}}
            >
                <Grid>
                    <Grid item>
                        <Box p={2}>
                            <Typography variant="h6">
                                Racun broj: {openedInvoice.broj}/{openedInvoice.godina}<br/>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 650}} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Artikal</TableCell>
                                        <TableCell align="right">Kolicina</TableCell>
                                        <TableCell align="right">Popust</TableCell>
                                        <TableCell align="right">Cijena</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {openedInvoice.items.map((item) => (
                                        <TableRow
                                            key={item.id}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">
                                                {item.artikal}
                                            </TableCell>
                                            <TableCell align="right">{item.kolicina}</TableCell>
                                            <TableCell align="right">{item.popust}</TableCell>
                                            <TableCell align="right">{item.cijena}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TableCell colSpan={3}>Ukupno</TableCell>
                                        <TableCell align="right">{openedInvoice.iznos}</TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                        <br/><br/>
                    </Grid>
                </Grid>
            </SwipeableDrawer>}
        </>
    )
}
