import React, {useEffect, useState} from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import {makeStyles, withStyles} from '@material-ui/core/styles'
import {useDispatch, useSelector} from 'react-redux'
import {
    getCompanies, getCurrentCompany, getCurrentEnu,
    getCurrentRole, getEnus,
    getUser, setCurrentCompany, setCurrentEnu,
    setCurrentRole, setEnus, setIsLoading
} from '../../containers/mainReducer'
import {useKeycloak} from '@react-keycloak/web'
import {
    Button,
    ClickAwayListener,
    Grid,
    Grow,
    Menu,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    TextField
} from '@material-ui/core'
import {AccountCircle} from '@material-ui/icons'
import {useHistory} from 'react-router'
import {Autocomplete} from '@material-ui/lab'
import {useAxios} from "../../utils/hooks";

const styles = () => ({
    root: {
        flexGrow: 1,
    },
    toolbarRoot: {
        paddingRight: 24,
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
        color: 'white'
    },
    menuButtonColor: {
        color: 'white'
    },
    title: {
        color: 'white',
    }
})

export const translateRole = (role) => {
    switch (role) {
        case 'OWNER':
            return 'Vlasnik'
        case 'ADMIN':
            return 'Administrator'
        default:
            return ''
    }
}

const useStyles = makeStyles(() => ({
    root: {
        color: 'white',
        '& .MuiFormLabel-root': {
            color: 'white'
        },
        '& .MuiIconButton-label': {
            color: 'white'
        },
    },
    inputRoot: {
        color: 'white',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white'
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white'
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white'
        },
        '&.Mui-disabled': {
            color: 'white'
        },

    }
}))

const Header = props => {
    const {classes, handleToggleDrawer} = props
    const classes1 = useStyles()
    const currentRole = useSelector(getCurrentRole)
    const user = useSelector(getUser)
    const companies = useSelector(getCompanies)
    const enus = useSelector(getEnus)
    const currentCompany = useSelector(getCurrentCompany)
    const currentEnu = useSelector(getCurrentEnu)
    const {keycloak} = useKeycloak()
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const history = useHistory()
    const axiosInstance = useAxios();

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
        setOpenRole(false)
    }
    const dispatch = useDispatch()
    const [openRole, setOpenRole] = React.useState(false)
    const anchorRef = React.useRef(null)

    const handleToggle = () => {
        setOpenRole((prevOpen) => !prevOpen)
    }

    const handleChangeRole = (role) => {
        dispatch(setCurrentRole(role))
        setOpenRole(false)
    }

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault()
            setOpenRole(false)
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(openRole)
    useEffect(() => {
        if (prevOpen.current === true && openRole === false) {
            anchorRef.current.focus()
        }
        prevOpen.current = openRole
    }, [openRole])

    useEffect(() => {
        if (currentCompany) {
            axiosInstance.current.get(`/reports/${currentCompany.pib}/enus`)
                .then(response => {
                    if (response && response.data) {
                        dispatch(setEnus(response.data));
                        if (response.data.length === 1) {
                            dispatch(setCurrentEnu(response.data[0]));
                        } else {
                            dispatch(setCurrentEnu(null));
                        }
                    }
                })
                .finally(() => {
                    dispatch(setIsLoading(false));
                });
        } else {
            dispatch(setEnus([]));
        }
    }, [currentCompany])

    return (
        <AppBar position="fixed">
            <Toolbar disableGutters={true} classes={{root: classes.toolbarRoot}}
                     style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <div>
                    <IconButton
                        aria-label="Open drawer"
                        onClick={handleToggleDrawer}
                        className={classes.menuButton}
                        color="inherit"
                    >
                        <MenuIcon/>
                    </IconButton>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    {keycloak.authenticated && user && user.roles.length > 1 && (
                        <>
                            <Button
                                ref={anchorRef}
                                aria-controls={open ? 'menu-list-grow' : undefined}
                                aria-haspopup="true"
                                onClick={handleToggle}
                                color="secondary"
                            >
                                {translateRole(currentRole)}
                            </Button>
                            <Popper open={openRole} anchorEl={anchorRef.current} role={undefined} transition
                                    disablePortal>
                                {({TransitionProps, placement}) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                                    >
                                        <Paper>
                                            <ClickAwayListener onClickAway={handleClose}>
                                                <MenuList autoFocusItem={open} id="menu-list-grow"
                                                          onKeyDown={handleListKeyDown}>
                                                    {user && <MenuItem style={{color: '#285ca8'}}
                                                                       disabled>{user.name}</MenuItem>}

                                                    {user.roles.filter(role => role !== currentRole).map(role =>
                                                        <MenuItem key={role}
                                                                  onClick={() => {
                                                                      handleChangeRole(role)
                                                                  }
                                                                  }>{translateRole(role)}</MenuItem>
                                                    )}

                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </>
                    )}

                    {keycloak.authenticated && (
                        <Grid item>
                            <Button
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                className={classes.menuButtonColor}
                            >
                                <AccountCircle color="inherit"/>
                            </Button>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={open}
                                onClose={handleClose}
                            >
                                <MenuItem
                                    onClick={() => {
                                        history.push(`/settings`)
                                        handleClose()
                                    }}>Podešavanja
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        handleClose()
                                        keycloak.logout()
                                        history.push(`/`)
                                    }}>Odjava</MenuItem>
                            </Menu>
                        </Grid>
                    )}
                </div>
            </Toolbar>
            <Toolbar disableGutters={true} classes={{root: classes.toolbarRoot}}
                     style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <Grid container style={{display: 'flex', flexDirection: 'row', justifyContent: 'right'}}>
                    {currentRole === "OWNER" &&
                        <>
                            <Grid item xs={4} sm={4} lg={2} style={{marginLeft: 5, marginRight: 5}}>
                                {companies && companies.length > 0 &&
                                    <Autocomplete
                                        id="selectCurrentCompany"
                                        disabled={companies && companies.length === 1}
                                        defaultValue={companies && companies.length === 1 ? currentCompany : null}
                                        value={currentCompany}
                                        options={[...companies].sort((a, b) => {
                                            const nameA = a.naziv.toUpperCase() // ignore upper and lowercase
                                            const nameB = b.naziv.toUpperCase() // ignore upper and lowercase
                                            if (nameA < nameB) {
                                                return -1
                                            }
                                            if (nameA > nameB) {
                                                return 1
                                            }

                                            return 0
                                        })}
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        getOptionLabel={(option) => option.naziv}
                                        fullWidth
                                        size="small"
                                        classes={classes1}
                                        onChange={(event, value) => {
                                            dispatch(setCurrentCompany(value))
                                        }}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                       label="Firma"
                                                       placeholder="Izaberite firmu"
                                                       variant="outlined"/>}/>
                                }
                            </Grid>
                            <Grid item xs={4} sm={4} lg={2} style={{marginLeft: 5, marginRight: 5}}>
                                {enus && enus.length > 0 &&
                                    <Autocomplete
                                        id="selectCurrentEnu"
                                        disabled={enus && enus.length === 1}
                                        defaultValue={enus && enus.length === 1 ? currentEnu : null}
                                        value={currentEnu}
                                        options={[...enus].sort((a, b) => {
                                            const nameA = a.naziv.toUpperCase() // ignore upper and lowercase
                                            const nameB = b.naziv.toUpperCase() // ignore upper and lowercase
                                            if (nameA < nameB) {
                                                return -1
                                            }
                                            if (nameA > nameB) {
                                                return 1
                                            }

                                            return 0
                                        })}
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        getOptionLabel={(option) => option.naziv}
                                        fullWidth
                                        size="small"
                                        classes={classes1}
                                        onChange={(event, value) => {
                                            dispatch(setCurrentEnu(value))
                                        }}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                       label="Kasa"
                                                       placeholder="Izaberite kasu"
                                                       variant="outlined"/>}/>
                                }
                            </Grid>
                        </>
                    }
                </Grid>

            </Toolbar>
        </AppBar>
    )
}

export default withStyles(styles)(Header)
