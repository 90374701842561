import React from 'react'
import { useSelector } from 'react-redux'
import {
  getCurrentRole,
} from '../../containers/mainReducer'
import { Box } from '@material-ui/core'

const WarningMessage = () => {
  const currentRole = useSelector(getCurrentRole)
  if (!currentRole) return null;
  let message = '';

  if (['OWNER', 'ACCOUNTANT'].includes(currentRole) && message) {
    return (
      <Box p={2} textAlign={"center"} style={{color: 'red'}}>
        {message}
      </Box>
    )
  } else {
    return null
  }

}

export default WarningMessage
