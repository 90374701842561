import React, {useEffect, useState} from 'react'
import {
    Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@material-ui/core'
import {useAxios} from '../../utils/hooks'
import {
    getCurrentCompany,
    setIsLoading,
} from '../mainReducer'
import {useDispatch, useSelector} from 'react-redux'
import moment from 'moment-timezone'
import MomentUtils from '@date-io/moment'
import 'moment/locale/me'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers'
import Grid from '@material-ui/core/Grid'

export default function Reports() {
    const [rows, setRows] = useState({})
    const [items, setItems] = useState({})
    const [dateFrom, setDateFrom] = useState(moment().startOf('day').valueOf())
    const [dateTo, setDateTo] = useState(moment().endOf('day').valueOf())
    const [refreshCounter, setRefreshCounter] = useState(0)
    const dispatch = useDispatch()
    const currentCompany = useSelector(getCurrentCompany)

    const axiosInstance = useAxios()

    useEffect(() => {
        const dateFromUrl = encodeURIComponent(moment(dateFrom).format('YYYY-MM-DDTHH:mm:ss'))
        const dateToUrl = encodeURIComponent(moment(dateTo).format('YYYY-MM-DDTHH:mm:ss'))

        if (!!axiosInstance.current && currentCompany) {
            dispatch(setIsLoading(true))
            axiosInstance.current.get(`/reports/${currentCompany.pib}/summary?dateFrom=${dateFromUrl}&dateTo=${dateToUrl}&invoiceType=racun,sumarni`)
                .then(({data}) => {
                    //reduce result to hash map by kasa
                    const result = data.reduce((acc, item) => {
                        if (!acc[item.kasa]) {
                            acc[item.kasa] = {}
                        }
                        acc[item.kasa][item.vrstaplacanja] = item.iznos
                        return acc
                    }, {})
                    setRows(result)

                    axiosInstance.current.get(`/reports/${currentCompany.pib}/goods-turnover?dateFrom=${dateFromUrl}&dateTo=${dateToUrl}&invoiceType=racun,sumarni`)
                        .then(({data}) => {
                            //reduce result to hash map by kasa
                            const result = data.reduce((acc, item) => {
                                if (!acc[item.kasa]) {
                                    acc[item.kasa] = {}
                                }
                                acc[item.kasa][item.artikal] = {
                                    cijena: item.cijena,
                                    kolicina: item.kolicina,
                                    iznos: item.iznos
                                }
                                return acc
                            }, {})

                            setItems(result)
                        })
                        .finally(() => {
                            dispatch(setIsLoading(false))
                        })
                })

        }
    }, [dispatch, axiosInstance, currentCompany, dateFrom, dateTo, refreshCounter])

    return (
        <>
            <Box>
                <Grid container style={{paddingTop: 80}}>
                    <MuiPickersUtilsProvider locale="me" utils={MomentUtils}>
                        <Grid item style={{width: '175px'}}>
                            <KeyboardDatePicker
                                autoOk
                                disableToolbar
                                size="small"
                                variant="inline"
                                inputVariant="outlined"
                                margin="normal"
                                id="date-picker-dialog-from"
                                label="Datum od"
                                format="DD.MM.yyyy."
                                value={moment(dateFrom)}
                                onChange={(value) => {
                                    setDateFrom(moment(value).startOf('day').valueOf())
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                style={{paddingRight: 5}}
                            />
                        </Grid>
                        <Grid item style={{width: '175px'}}>
                            <KeyboardDatePicker
                                autoOk
                                disableToolbar
                                size="small"
                                variant="inline"
                                inputVariant="outlined"
                                margin="normal"
                                id="date-picker-dialog-to"
                                label="Datum do"
                                format="DD.MM.yyyy."
                                value={dateTo}
                                onChange={(value) => {
                                    setDateTo(moment(value).endOf('day').valueOf())
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                style={{paddingLeft: 5, paddingRight: 5}}
                            />
                        </Grid>
                    </MuiPickersUtilsProvider>

                    <Button
                        color="default"
                        onClick={() => {
                            setRefreshCounter(refreshCounter + 1)
                        }}>
                        Osvježi
                    </Button>
                </Grid>
            </Box>
            <br/>

            <h2>Promet po vrsti plaćanja</h2>
            <Grid container spacing={1}>
                {Object.keys(rows).map((kasa) => <Grid item xs={12} sm={12} md={6} lg={4} p={1}>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 700}} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={2}>{kasa}</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.keys(rows[kasa]).map((nacinplacanja) => (
                                        <TableRow key={nacinplacanja}>
                                            <TableCell component="th" scope="row">
                                                {nacinplacanja}
                                            </TableCell>
                                            <TableCell align="right">{rows[kasa][nacinplacanja]}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                )}
            </Grid>

            <h2>Promet robe i usluga</h2>
            <Grid container spacing={1}>
                {Object.keys(items).map((kasa) => <Grid item xs={12} sm={12} md={6} lg={4} p={1}>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 700}} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={4}>{kasa}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.keys(items[kasa])
                                        .sort((a, b) => items[kasa][b].kolicina - items[kasa][a].kolicina)
                                        .map((artikal) => (
                                        <TableRow key={artikal}>
                                            <TableCell component="th" scope="row">
                                                {artikal}
                                            </TableCell>
                                            <TableCell align="right">{items[kasa][artikal].cijena}</TableCell>
                                            <TableCell align="right">{items[kasa][artikal].kolicina}</TableCell>
                                            <TableCell align="right">{items[kasa][artikal].iznos}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                )}
            </Grid>
        </>
    )
}
