import { useEffect, useRef } from 'react';
import axios from 'axios';
import { useKeycloak } from '@react-keycloak/web';
const baseURL = process.env.REACT_APP_API_URL;

export const useAxios = () => {
  const axiosInstance = useRef();
  const { keycloak, initialized } = useKeycloak();

  useEffect(() => {
    axiosInstance.current = axios.create({
      baseURL
    });

    axiosInstance.current.interceptors.request.use(config => {
      config.headers.authorization = 'Bearer ' + keycloak.token;
      return config;
    });

/*    axiosInstance.current.interceptors.response.use((response) => response, (error) => {
      if (error.response.status === 401 || error.response.status === 403) {
        console.log('error.response.status', error.response.status)
        window.location = '/login';
      }
    });*/

    return () => {
      axiosInstance.current = undefined;
    };
  }, [initialized, keycloak.token]);

  return axiosInstance;
};