import {createSlice} from '@reduxjs/toolkit'

export const main = createSlice({
    name: 'main',
    initialState: {
        drawerOpen: false,
        currentRoute: null,
        currentRole: null,
        snackbar: {
            show: false,
            message: '',
            type: null
        },
        isLoading: false,
        user: null,
        drawerOpened: localStorage.getItem('fiscall-drawer-opened') === 'true',
        companies: [],
        enus: [],
        currentCompany: null,
        currentEnu: null
    },
    reducers: {
        setCurrentRoute: (state, action) => {
            state.currentRoute = action.payload
        },
        setCurrentRole: (state, action) => {
            state.currentRole = action.payload
        },
        setSnackbar: (state, action) => {
            state.snackbar = action.payload
        },
        setSuccessSnackbar: (state, action) => {
            state.snackbar = {
                show: true,
                message: action.payload,
                type: 'success'
            }
        },
        setErrorSnackbar: (state, action) => {
            state.snackbar = {
                show: true,
                message: action.payload,
                type: 'error'
            }
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload
        },
        setUser: (state, action) => {
            state.user = action.payload
        },
        setDrawerOpened: (state, action) => {
            state.drawerOpened = action.payload
        },
        setCompanies: (state, action) => {
            state.companies = action.payload
            if (action.payload.length === 1) {
                state.currentCompany = action.payload[0]
            }
        },
        setEnus: (state, action) => {
            state.enus = action.payload
            if (action.payload.length === 1) {
                state.currentEnu = action.payload[0]
            }
        },
        setCurrentCompany: (state, action) => {
            if (!action.payload) {
                state.currentEnu = null
            }
            state.currentCompany = action.payload
        },
        setCurrentEnu: (state, action) => {
            state.currentEnu = action.payload
        }
    },
})
//actions
export const {
    setCurrentRole,
    setSnackbar,
    setSuccessSnackbar,
    setErrorSnackbar,
    setIsLoading,
    setUser,
    setDrawerOpened,
    setCurrentRoute,
    setCompanies,
    setEnus,
    setCurrentCompany,
    setCurrentEnu
} = main.actions

//selectors
export const getSnackbar = state => state.main.snackbar
export const getIsLoading = state => state.main.isLoading
export const getUser = state => state.main.user
export const getCurrentRole = state => state.main.currentRole
export const getDrawerOpened = state => state.main.drawerOpened
export const getCurrentRoute = state => state.main.currentRoute
export const getCompanies = state => state.main.companies
export const getEnus = state => state.main.enus
export const getCurrentCompany = state => state.main.currentCompany
export const getCurrentEnu = state => state.main.currentEnu

export default main.reducer